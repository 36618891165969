// src/Footer.js

import { Link } from "react-router-dom";
import React from "react";
import "../css/footer.css";
import logoFooter from "../images/logo-footer.svg";
import logoColony from "../images/colony.svg";

function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="footer-col">
          <img src={logoFooter} alt="Colony" className="logo-footer" />
          <div className="supported">
            <p className="mr-10">In Partnership with</p>{" "}
            <img src={logoColony} alt="Colony" className="logo-footer" />{" "}
          </div>
        </div>
        <div className="footer-col">
          <h4 className="title">Products</h4>
          <ul>
            <li>
              <Link to="/staking">Staking</Link>
            </li>
            <li>
              <Link to="/early-stage">
                Early-Stage Program & Liquid Vesting
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-col">
          <h4 className="title">Discover</h4>
          <ul>
            <li>
              <Link to="https://medium.com/@seedcircle">Blog/Medium </Link>
            </li>
            <li>
              <Link to="#">Careers</Link>
            </li>
            <li>
              <Link to="#">Ambassador Program </Link>
            </li>
            <li>
              <Link to="#">Brand Assets </Link>
            </li>
          </ul>
        </div>
        <div className="footer-col">
          <h4 className="title">Subscribe to the Seed Circle Newsletter</h4>
          <form
            action="https://gmail.us6.list-manage.com/subscribe/post?u=4ba9e70a49abd61bca1ba67f3&amp;id=6ccc0fa402"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate=""
            data-np-checked="1"
          >
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_4ba9e70a49abd61bca1ba67f3_6ccc0fa402"
                tabIndex="-1"
                defaultValue=""
                data-np-checked="1"
              />
            </div>

            <div className="newsletter d-flex">
              <input
                type="email"
                defaultValue=""
                name="EMAIL"
                placeholder="Subscribe here"
              />
              <button type="submit" className="btn btn-full">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="div"></div>
      <div className="footer-social">
        <h5>Follow Seed Circle on Social</h5>
        <div className="social">
          <Link to="https://x.com/SeedCircle_">
            <span className="icon-twitter"></span>
          </Link>
          <Link to="#">
            <span className="icon-youtube"></span>
          </Link>
          <Link to="#">
            <span className="icon-linkedin"></span>
          </Link>
          <Link to="https://medium.com/@seedcircle">
            <span className="icon-medium"></span>
          </Link>
          <Link to="">
            <span className="icon-telegram"></span>
          </Link>
          <Link to="https://discord.gg/dwwjBwbg">
            <span className="icon-discord"></span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
