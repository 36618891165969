//About

import React from "react";
import "../css/community.css";

function About() {
  return (
    <div className="content">
      <section className="community container d-flex f-align-center">
        <h2 className="color-white mt-0 mb-0">About</h2>
      </section>
    </div>
  );
}

export default About;
