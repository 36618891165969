// Navigation

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "../css/navbar.css";
import logo from "../images/logo.svg";
import itemStake from "../images/item-complience.png";
import itemLiquid from "../images/item-liquid.png";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className={menuOpen ? "open" : "close"}>
      <div className="container clearfix d-flex f-justify-between">
        <Link to="/" className="logo">
          <img src={logo} className="nav-logo" title="Colony" />
        </Link>
        <ul className="nav">
          <li className="has-children">
            <a>Products</a>
            <div>
              <ul id="submenu">
                <li>
                  <NavLink to="/staking" onClick={closeMenu}>
                    <img src={itemStake} />
                    Staking
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/early-stage"
                    className="small-text"
                    onClick={closeMenu}
                  >
                    {" "}
                    <img src={itemLiquid} />
                    Early-Stage Program & <br />
                    Liquid Vesting DEX
                    <span>Seed/Private sales & locked tokens tradability</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <NavLink to="/fundraising" onClick={closeMenu}>
              Apply for Fundraise
            </NavLink>
          </li>
          <Link to="/" className="btn btn-light">
            Coming soon
          </Link>
        </ul>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};
