import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { Navbar } from "./components/Navbar";
import Footer from "./components/Footer.js";
import ScrollToTop from "./components/ScrollToTop";
import Loading from "./components/Loading";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Staking from "./pages/Staking.js";
import Early from "./pages/Early-Stage.js";
import Fundraising from "./pages/Fundraising.js";

import "./css/layout.css";
import "./css/ui.css";
import "./css/loading.css";
import "./css/icons.css";
import "./css/style.css";
import "./css/animations.css";
import "./css/responsive.css";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 300);
  }, []);
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="main">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/early-stage" element={<Early />} />
          <Route path="/fundraising" element={<Fundraising />} />
        </Routes>

        <Footer />
      </div>
    </div>
  );
}

export default App;
