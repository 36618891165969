//Fundraising

import React from "react";
import "../css/fundraising.css";
import { Link } from "react-router-dom";

//assets
import imageApply from "../images/apply.png";
import imageContact from "../images/contact.png";

import videoFundraising from "../video/fundraising.mp4";

function Fundraising() {
  return (
    <div className="content">
      <section className="fundraising hero container-wide d-flex f-align-center f-justify-center">
        <div className="container d-flex f-align-center">
          <div className="w-50">
            <h1 className="text-shadow">
              Onboard a community of thousands of investors and future users
            </h1>
            <p>
              Seed Circle's Investment DAO, through its Early-Stage feature,
              offers an efficient decentralized fundraising platform. You can
              decentralize your capitalization tables, mitigate selling
              pressure, all while gaining a vibrant community of investors and
              future users right from the outset. This perfect alignment of
              interests sets the stage for a bright future for your project.
              <br />
              <br />
              And that's not all – you also have the option to collaborate
              directly with Seed Circle's founding team, which can provide
              extensive support in various areas, including tokenomics, business
              development, marketing, community engagement, and legal expertise.
            </p>{" "}
          </div>

          <div className="w-50 d-flex f-justify-center">
            <video autoPlay loop muted playsInline className="funding-vid">
              <source src={videoFundraising} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <section className="fundraising-content container-wide d-flex f-align-center f-justify-center">
        <div className="contact container d-flex f-align-center">
          <div className="w-50 d-flex f-dir-col f-justify-center f-align-center text-center">
            <img src={imageApply} />
            <h3>Apply for Fundraise</h3>
            <p>Looking to fundraise for your seed/private sale? Apply now.</p>
            <Link to="/" className="btn btn-light">
              Application form
            </Link>
          </div>
          <div className="w-50 d-flex f-dir-col f-justify-center f-align-center text-center">
            <img src={imageContact} />
            <h3>Get in Touch for Seed Circle Services</h3>
            <p>
              Need help with tokenomics, tokenization, growth and marketing,
              legal matters, and more? Contact us for more details about our
              services.
            </p>
            <Link to="/" className="btn btn-light">
              Contact form
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Fundraising;
