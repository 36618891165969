//Staking

import React from "react";
import { Link } from "react-router-dom";
import "../css/staking.css";

//assets
import iconAllocation from "../images/icon-allocation.png";
import iconIncome from "../images/icon-income.png";
import iconDistributed from "../images/icon-distributed.png";
import iconStakers from "../images/icon-stakers.png";
import videoStake from "../video/stake.mp4";

function Staking() {
  return (
    <div className="content stake">
      <section className="staking hero container-wide d-flex f-dir-col f-align-center f-justify-center">
        <div className="container d-flex f-align-center f-justify-between">
          <div className="w-50 hero-content">
            <h1 className="text-shadow">Stake & Enjoy Multiple Benefits!</h1>
            <p>
              Seed Circle’s inclusive platform is tailored for both passive
              income enthusiasts and active investors. When you participate in
              staking Seed Circle's upcoming token, you unlock a world of
              diverse utilities:
            </p>{" "}
          </div>

          <div className="w-50 d-flex f-justify-center">
            <video autoPlay loop muted playsInline className="funding-vid">
              <source src={videoStake} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="stake-content container-wide d-flex f-dir-col f-align-center f-justify-center">
          <div className="stake-items container">
            <div>
              <img src={iconAllocation} />
              <p>
                Access to allocations for investing in seed/private sales of
                early-stage projects.
              </p>
            </div>
            <div>
              <img src={iconIncome} />
              <p>
                Enjoy the potential for passive income through regular airdrops
                from early-stage projects.
              </p>
            </div>
            <div>
              <img src={iconDistributed} />
              <p>
                Earn a share of platform fees, which are continually
                redistributed to the valued stakers.
              </p>
            </div>
            <div>
              <img src={iconStakers} />
              <p>
                And much more utility is yet to be unveiled, adding further
                value for stakers.
              </p>
            </div>
          </div>
          <Link to="/" className="btn btn-light mt-50">
            Start Staking
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Staking;
