//Early Stage

import React from "react";
import "../css/early.css";
import { Link } from "react-router-dom";

//assets

import videoSeed from "../video/early.mp4";
import videoLiquidity from "../video/liquidity.mp4";

function Early() {
  return (
    <div className="content">
      <section className="early hero container-wide d-flex f-align-center">
        <div className="container d-flex f-align-center f-justify-between">
          <div className="w-50 hero-content">
            <h1>
              Early-Stage Program <br />
              <span className="gradient-text">
                Seed and Private Sales Access
              </span>
            </h1>
            <p>
              Seed Circle's Early-stage feature provides stakers access to
              invest in early-stage Monad projects before IDOs through Seed
              and Private sales. This once-exclusive opportunity for VCs and
              angel investors is now available to you.
              <br /> <br />
              Explore early-stage Monad projects, upvote, comment, rate, and
              access project scores generated from thousands of community
              analyses and due diligences. Invest in seed and private sales for
              the first time and trade your locked/vesting tokens freely, thanks
              to a unique Liquid Vesting solution.
            </p>{" "}
            <Link to="/" className="btn btn-light">
              Start staking to access Early-Stage soon
            </Link>
          </div>

          <div className="w-50 d-flex f-justify-center">
            <video autoPlay loop muted playsInline className="max-hero-vid">
              <source src={videoSeed} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <section className="liquid-vesting container-wide d-flex f-align-center f-justify-center">
        <div className="container d-flex f-align-center">
          <div className="w-50 d-flex f-align-center f-justify-center">
            <video autoPlay loop muted playsInline>
              <source src={videoLiquidity} type="video/mp4" />
            </video>
          </div>
          <div className="w-50">
            <h1>
              Liquid Vesting <br />
              <span className="gradient-text">
                Shaping a Liquid Future for Primary Markets
              </span>
            </h1>
            <p>
              Liquid Vesting is super small on the website while it’s actually
              one of the main features of Seed Circle. That’s what’s unique
              about Colony & Seed Circle, it’s a new product, never built before
              in the entire crypto space. It needs to be highlighted properly.
            </p>{" "}
            <div className="btn-container">
              <Link to="" className="btn btn-disabled">
                Learn all about Liquid Vesting
              </Link>
              <Link to="" className="btn btn-disabled">
                Access Seed Circle’s DEX
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Early;
