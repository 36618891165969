//Homepage

import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

//css
import "../css/home.css";

//components
import ScrollButton from "../components/ScrollButton";

//assets
import iconCommunity from "../images/community-icon.svg";
import iconBuilders from "../images/builders-icon.svg";
import itemSupport from "../images/item-support.png";
import itemCommunity from "../images/item-coomunity.png";
import itemPrelaunch from "../images/item-prelaunch.png";
import itemActive from "../images/item-active.png";
import itemFundraising from "../images/item-fundraising.png";
import itemUser from "../images/item-user-friendly.png";
import itemComplience from "../images/item-complience.png";
import itemLiquid from "../images/item-liquid.png";
import item1 from "../images/item-1.png";
import item2 from "../images/item-2.png";

import videoHero from "../video/hero-c.mp4";
import videoMission from "../video/mission.mp4";
import videoInvestment from "../video/investment.mp4";

function Home() {
  //Slick slider
  var platform = {
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          vertical: true,
          autoplay: false,
          autoplaySpeed: 2000,
          speed: 400,
          slidesToShow: 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          vertical: true,
          autoplay: true,
          autoplaySpeed: 2000,
          speed: 400,
          slidesToShow: 3,
          centerMode: true,
        },
      },
    ],
  };

  //Load and active sections for animations
  const [sections, setSections] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Function to activate sections based on scroll
    const activateSections = () => {
      const windowScrollTop = window.scrollY;
      const windowHeight = window.innerHeight;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (loaded && sectionTop - windowScrollTop < windowHeight / 1) {
          section.classList.add("active");
        }
      });
    };

    // Event listener for scroll
    const handleScroll = () => {
      activateSections();
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loaded, sections]);

  useEffect(() => {
    const sectionElements = document.querySelectorAll(
      ".content section:not(.active)"
    );
    setSections(Array.from(sectionElements));

    // Simulate setting loaded to true after a delay (2 seconds in this example)
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 2000);

    // Clear the timeout on component unmount to prevent memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="content homepage">
      <section className="home hero container-wide d-flex f-align-center f-justify-center">
        <div className="container-tiny d-flex f-dir-col f-align-center">
          <h4 className="font-secondary text-center color-purple text-upper text-shadow">
            The DeFi space has evolved. Now Fundraising has, too.{" "}
          </h4>
          <h1 className="text-center text-shadow">
            Seed Circle's Inclusive DAO:
            <br /> A new era for Decentralized Fundraising.
          </h1>
          <p className="text-center">
            The first inclusive and decentralized fundraising platform, built
            for the Monad ecosystem, designed to offer everyone unprecedented
            access to seed and private sales investments in early-stage
            projects. Seed Circle also offers a unique Liquid Vesting solution
            that unlocks unparalleled freedom for trading locked/vesting tokens.
          </p>
          <ScrollButton />
        </div>
        <div>
          {" "}
          <video autoPlay loop muted playsInline className="hero-vid">
            <source src={videoHero} type="video/mp4" />
          </video>
        </div>
      </section>

      <section
        className="mission container-wide d-flex f-align-center f-justify-center animate"
        id="target-section"
      >
        <div className="container d-flex f-align-center">
          <div className="w-50 d-flex f-justify-center animate-elem animate-fadeIn animate-scale-from-big animate-delay-02">
            <video autoPlay loop muted playsInline className="mission-vid">
              <source src={videoMission} type="video/mp4" />
            </video>
          </div>
          <div className="w-50 whitespace-5per animate-elem animate-fadeIn animate-scale-from-big animate-delay-03">
            <h2>Our Dual Mission</h2>
            <p>
              Seed Circle is more than just a platform; it's a catalyst for
              progress, aiming to democratize early-stage investments. We are
              driven by a dual mission: to empower pioneering projects with
              crucial early-stage capital and to offer the crypto community a
              gateway to unprecedented opportunities, once exclusive to VCs and
              large funds.
              <br />
              <br />
              With us, builders and projects gain essential ecosystem support,
              organic early exposure, and a thriving community of investors and
              future users. It's a win-win scenario where innovation flourishes,
              and investors join the journey to shape the future of blockchain
              and cryptocurrency.
            </p>
          </div>
        </div>
      </section>

      <section className="investment container-wide d-flex f-align-center f-justify-center animate">
        <div className="container d-flex f-align-center">
          <div className="w-50 whitespace-5per animate-elem animate-fadeIn animate-scale-from-big animate-delay-02">
            <div className="label gradient-pink font-secondary">
              <img src={iconCommunity} className="mr-5" />
              COMMUNITY
            </div>
            <h2>Introducing Inclusive Investment DAO</h2>
            <p>
              At the heart of Seed Circle's vision is our Inclusive Investment
              DAO. It's more than just a platform; it's a movement towards
              financial inclusion. By joining our Investment DAO, you become an
              essential contributor to investment decisions, and an early
              investor of new innovative blockchain projects, with the
              unprecedented early liquidity and tradability for locked
              investments. <br />
              <br />
              Access advanced investment tools like seasoned VCs. Join a diverse
              community of thousands of investors, collaborating on due
              diligence and supporting early-stage projects —a synergy that
              substantially enhances your investment journey.
            </p>
            <Link to="/early-stage" className="btn btn-light">
              discover early-stage product: Invest in Seed / private sales
            </Link>
          </div>
          <div className="investment-feature w-50 d-flex f-justify-center animate-elem animate-fadeIn animate-scale-from-big animate-delay-03">
            <video autoPlay loop muted>
              <source src={videoInvestment} type="video/mp4" />
            </video>
            <img src={item1} className="item-1" />
            <img src={item2} className="item-2" />
          </div>
        </div>
      </section>

      <section className="builders container-wide d-flex f-align-center f-justify-center animate">
        <div className="container d-flex f-align-center">
          <div className="w-50 whitespace-5per animate-elem animate-fadeIn animate-scale-from-big animate-delay-02">
            <div className="label gradient-purple font-secondary">
              <img src={iconBuilders} className="mr-5" />
              BUILDERS & PROJECTS
            </div>
            <h2>The Platform for Efficient Decentralized Fundraising</h2>
            <p>
              Empower your project's success with Seed Circle – the go-to
              platform for decentralized fundraising and community engagement,
              offering exclusive opportunities beyond what VCs can provide.
            </p>
            <Link to="/fundraising" className="btn btn-light">
              Learn more & Apply for Fundraise
            </Link>
          </div>
          <Slider
            {...platform}
            className="features animate-elem animate-fadeIn animate-scale-from-big animate-delay-03"
          >
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemActive} />
                  <h4>Active Monad Engagement</h4>
                </div>
                <div className="card-back">
                  <h4>Active Monad Engagement</h4>
                  <p>
                    Engage with thousands of active participants on Monad.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemFundraising} />
                  <h4>Transparent & On-chain Fundraising</h4>
                </div>
                <div className="card-back">
                  <h4>Transparent & On-chain Fundraising</h4>
                  <p>
                    Transparent on-chain fundraising data, easily accessible.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemUser} />
                  <h4>User-Friendly App</h4>
                </div>
                <div className="card-back">
                  <h4>User-Friendly App</h4>
                  <p>
                    Simplified fundraising through an intuitive, modern UX/UI
                    user-friendly app.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemComplience} />
                  <h4>Compliance Measures in place</h4>
                </div>
                <div className="card-back">
                  <h4>Compliance Measures in place</h4>
                  <p>
                    A serious legal framework equipped with the necessary
                    compliance measures.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemLiquid} />
                  <h4>Liquid Vesting Solution</h4>
                </div>
                <div className="card-back">
                  <h4>Liquid Vesting Solution</h4>
                  <p>Liquid Vesting in place to mitigate selling pressure.</p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemSupport} />
                  <h4>Tailored Launch Support</h4>
                </div>
                <div className="card-back">
                  <h4>Tailored Launch Support</h4>
                  <p>
                    Comprehensive support from idea to launch, tailored to your
                    needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemCommunity} />
                  <h4>Community-Driven Fundraising</h4>
                </div>
                <div className="card-back">
                  <h4>Community-Driven Fundraising</h4>
                  <p>
                    Embrace the power of decentralized community-driven
                    fundraising.
                  </p>
                </div>
              </div>
            </div>
            <div className="feature-item">
              <div className="card">
                <div className="card-front">
                  <img src={itemPrelaunch} />
                  <h4>Pre-Launch User Base</h4>
                </div>
                <div className="card-back">
                  <h4>Pre-Launch User Base</h4>
                  <p>
                    •Access community from the get-go, fostering a substantial
                    user base pre-launch.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>

      <section className="community container-wide d-flex f-align-center f-justify-center f-dir-col animate">
        <div className="container d-flex f-align-center f-dir-col animate-elem animate-fadeIn animate-scale-from-big animate-delay-02">
          <h4 className="font-secondary color-purple text-upper text-shadow">
            Together is better.
          </h4>
          <h2>Join our community.</h2>
        </div>
        <div className="container d-flex f-align-center f-justify-center animate-elem animate-fadeIn animate-scale-from-big animate-delay-03">
          <a className="social-item" href="https://x.com/SeedCircle_">
            <span className="icon-twitter"></span>Twitter
          </a>
          <a className="social-item" href="https://discord.gg/dwwjBwbg">
            <span className="icon-discord"></span>Discord
          </a>
          <a className="social-item" href="https://medium.com/@seedcircle">
            <span className="icon-medium"></span>Medium
          </a>
          <a className="social-item">
            <span className="icon-linkedin"></span>LinkedIn
          </a>
        </div>
      </section>
    </div>
  );
}

export default Home;
